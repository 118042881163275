// COLOURS
$black: #202020;
$grey: #dfdfdf;
$greyLight: #ededed;
$greyDark: #acacac;
$greenPrimary: #8dde43;
$greenSecondary: #a8ed68;
$red: #e53030;
$white: #ffffff;

// FONTS
$primaryFontFamily: 'Open Sans', sans-serif;
$fontXl: 6vh;
$fontLg: 3.5vh;
$fontWeightLight: 300;
$fontWeightExtraBold: 800;


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gov__container {
  padding-top: 50px;
  margin: 0 auto; // center the content of the container div
  width: 78%; // sets a width on the sides
  height: 92%;

  .gov__logo {
    font-size: 25px;
    span:nth-child(1) {
      font-weight: $fontWeightLight;
    }
    span:nth-child(1).gov__deloitte {
      font-weight: $fontWeightExtraBold;
    }
    span:nth-child(2) {
      color: $greenPrimary;
    }
  }

  .input {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 30%;
    input[type=text], select {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  .gov__btn {
    width: 364px;
    height: 72px;
    border-radius: 6px;
    background-color: $greenPrimary;
    border-style: solid;
    border-color: $greenPrimary;
    font-weight: $fontWeightExtraBold;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s ease;
    display: inline-block;

    &:active {
      border-style: none;
    }

    &:disabled {
      background-color: $grey;
      border-color: $grey;
      color: #787878;
      cursor: not-allowed;
    }

    &:hover:not([disabled]) {
      background-color: $greenSecondary;
      border-color: $greenSecondary;
    }
  }

}
